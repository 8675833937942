/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: auto;
    src: local('Gilroy-Bold'), url('../public/fonts/Gilroy-Bold.woff') format('woff');
    }


    @font-face {
    font-family: 'Gilroy-Heavy';
    font-style: normal;
    font-weight: auto;
    src: local('Gilroy-Heavy'), url('../public/fonts/Gilroy-Heavy.woff') format('woff');
    }


    @font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: auto;
    src: local('Gilroy-Light'), url('../public/fonts/Gilroy-Light.woff') format('woff');
    }


    @font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: auto;
    src: local('Gilroy-Medium'), url('../public/fonts/Gilroy-Medium.woff') format('woff');
    }


    @font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: auto;
    src: local('Gilroy-Regular'), url('../public/fonts/Gilroy-Regular.woff') format('woff');
    }
