@import "./app/directives/flatpickr.css";
@import "@ng-select/ng-select/themes/default.theme.css";

:root {
  --color-primary: #36c2d7;
  --color-primary-50: color-mix(in srgb, var(--color-primary), #fff 90%);
  --color-primary-100: color-mix(in srgb, var(--color-primary), #fff 80%);
  --color-primary-200: color-mix(in srgb, var(--color-primary), #fff 60%);
  --color-primary-300: color-mix(in srgb, var(--color-primary), #fff 40%);
  --color-primary-400: color-mix(in srgb, var(--color-primary), #fff 20%);
  --color-primary-500: color-mix(in srgb, var(--color-primary), #fff 0%);
  --color-primary-600: color-mix(in srgb, var(--color-primary), #000 20%);
  --color-primary-700: color-mix(in srgb, var(--color-primary), #000 40%);
  --color-primary-800: color-mix(in srgb, var(--color-primary), #000 60%);
  --color-primary-900: color-mix(in srgb, var(--color-primary), #000 80%);
  --color-primary-950: color-mix(in srgb, var(--color-primary), #000 90%);
  --color-on-primary: #000;

  --color-secondary: #167f9f;
  --color-secondary-50: color-mix(in srgb, var(--color-secondary), #fff 90%);
  --color-secondary-100: color-mix(in srgb, var(--color-secondary), #fff 80%);
  --color-secondary-200: color-mix(in srgb, var(--color-secondary), #fff 60%);
  --color-secondary-300: color-mix(in srgb, var(--color-secondary), #fff 40%);
  --color-secondary-400: color-mix(in srgb, var(--color-secondary), #fff 20%);
  --color-secondary-500: color-mix(in srgb, var(--color-secondary), #fff 0%);
  --color-secondary-600: color-mix(in srgb, var(--color-secondary), #000 20%);
  --color-secondary-700: color-mix(in srgb, var(--color-secondary), #000 40%);
  --color-secondary-800: color-mix(in srgb, var(--color-secondary), #000 60%);
  --color-secondary-900: color-mix(in srgb, var(--color-secondary), #000 80%);
  --color-secondary-950: color-mix(in srgb, var(--color-secondary), #000 90%);
  --color-on-secondary: #fff;

  --color-info: #0ea5e9;
  --color-info-50: color-mix(in srgb, var(--color-info), #fff 90%);
  --color-info-100: color-mix(in srgb, var(--color-info), #fff 80%);
  --color-info-200: color-mix(in srgb, var(--color-info), #fff 60%);
  --color-info-300: color-mix(in srgb, var(--color-info), #fff 40%);
  --color-info-400: color-mix(in srgb, var(--color-info), #fff 20%);
  --color-info-500: color-mix(in srgb, var(--color-info), #fff 0%);
  --color-info-600: color-mix(in srgb, var(--color-info), #000 20%);
  --color-info-700: color-mix(in srgb, var(--color-info), #000 40%);
  --color-info-800: color-mix(in srgb, var(--color-info), #000 60%);
  --color-info-900: color-mix(in srgb, var(--color-info), #000 80%);
  --color-info-950: color-mix(in srgb, var(--color-info), #000 90%);
  --color-on-info: #000;

  --color-success: #22c55e;
  --color-success-50: color-mix(in srgb, var(--color-success), #fff 90%);
  --color-success-100: color-mix(in srgb, var(--color-success), #fff 80%);
  --color-success-200: color-mix(in srgb, var(--color-success), #fff 60%);
  --color-success-300: color-mix(in srgb, var(--color-success), #fff 40%);
  --color-success-400: color-mix(in srgb, var(--color-success), #fff 20%);
  --color-success-500: color-mix(in srgb, var(--color-success), #fff 0%);
  --color-success-600: color-mix(in srgb, var(--color-success), #000 20%);
  --color-success-700: color-mix(in srgb, var(--color-success), #000 40%);
  --color-success-800: color-mix(in srgb, var(--color-success), #000 60%);
  --color-success-900: color-mix(in srgb, var(--color-success), #000 80%);
  --color-success-950: color-mix(in srgb, var(--color-success), #000 90%);
  --color-on-success: #000;

  --color-warning: #eab308;
  --color-warning-50: color-mix(in srgb, var(--color-warning), #fff 90%);
  --color-warning-100: color-mix(in srgb, var(--color-warning), #fff 80%);
  --color-warning-200: color-mix(in srgb, var(--color-warning), #fff 60%);
  --color-warning-300: color-mix(in srgb, var(--color-warning), #fff 40%);
  --color-warning-400: color-mix(in srgb, var(--color-warning), #fff 20%);
  --color-warning-500: color-mix(in srgb, var(--color-warning), #fff 0%);
  --color-warning-600: color-mix(in srgb, var(--color-warning), #000 20%);
  --color-warning-700: color-mix(in srgb, var(--color-warning), #000 40%);
  --color-warning-800: color-mix(in srgb, var(--color-warning), #000 60%);
  --color-warning-900: color-mix(in srgb, var(--color-warning), #000 80%);
  --color-warning-950: color-mix(in srgb, var(--color-warning), #000 90%);
  --color-on-warning: #000;

  --color-danger: #ef4444;
  --color-danger-50: color-mix(in srgb, var(--color-danger), #fff 90%);
  --color-danger-100: color-mix(in srgb, var(--color-danger), #fff 80%);
  --color-danger-200: color-mix(in srgb, var(--color-danger), #fff 60%);
  --color-danger-300: color-mix(in srgb, var(--color-danger), #fff 40%);
  --color-danger-400: color-mix(in srgb, var(--color-danger), #fff 20%);
  --color-danger-500: color-mix(in srgb, var(--color-danger), #fff 0%);
  --color-danger-600: color-mix(in srgb, var(--color-danger), #000 20%);
  --color-danger-700: color-mix(in srgb, var(--color-danger), #000 40%);
  --color-danger-800: color-mix(in srgb, var(--color-danger), #000 60%);
  --color-danger-900: color-mix(in srgb, var(--color-danger), #000 80%);
  --color-danger-950: color-mix(in srgb, var(--color-danger), #000 90%);
  --color-on-danger: #000;

  --color-gray: #9099c4;
  --color-gray-50: color-mix(in srgb, var(--color-gray), #fff 90%);
  --color-gray-100: color-mix(in srgb, var(--color-gray), #fff 80%);
  --color-gray-200: color-mix(in srgb, var(--color-gray), #fff 60%);
  --color-gray-300: color-mix(in srgb, var(--color-gray), #fff 40%);
  --color-gray-400: color-mix(in srgb, var(--color-gray), #fff 20%);
  --color-gray-500: color-mix(in srgb, var(--color-gray), #fff 0%);
  --color-gray-600: color-mix(in srgb, var(--color-gray), #000 20%);
  --color-gray-700: color-mix(in srgb, var(--color-gray), #000 40%);
  --color-gray-800: color-mix(in srgb, var(--color-gray), #000 60%);
  --color-gray-900: color-mix(in srgb, var(--color-gray), #000 80%);
  --color-gray-950: color-mix(in srgb, var(--color-gray), #000 90%);
  --color-on-gray: #000;

  --color-royal: #4b71e6;
  --color-royal-50: color-mix(in srgb, var(--color-royal), #fff 90%);
  --color-royal-100: color-mix(in srgb, var(--color-royal), #fff 80%);
  --color-royal-200: color-mix(in srgb, var(--color-royal), #fff 60%);
  --color-royal-300: color-mix(in srgb, var(--color-royal), #fff 40%);
  --color-royal-400: color-mix(in srgb, var(--color-royal), #fff 20%);
  --color-royal-500: color-mix(in srgb, var(--color-royal), #fff 0%);
  --color-royal-600: color-mix(in srgb, var(--color-royal), #000 20%);
  --color-royal-700: color-mix(in srgb, var(--color-royal), #000 40%);
  --color-royal-800: color-mix(in srgb, var(--color-royal), #000 60%);
  --color-royal-900: color-mix(in srgb, var(--color-royal), #000 80%);
  --color-royal-950: color-mix(in srgb, var(--color-royal), #000 90%);
  --color-on-royal: #000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* html, body { height: 100%; } */
body {
  margin: 0;
  font-family: Gilroy-Medium, sans-serif !important;

  [ngxerrors] {
    @apply block font-semibold text-sm text-danger mb-1 dark:text-danger-300;
  }
  div:has(> [ngxerrors] > div) {
    & > input,
    > select,
    .ng-select-container,
    .mtek-radio-options-container,
    > textarea {
      @apply text-danger border !border-danger rounded-lg;
    }
  }

  div > label {
    @apply after:text-lg;
  }

  div:has(
      > input[required],
      select[required],
      mtek-radio-group[required],
      mtek-datepicker[required],
      ng-select[required],
      textarea[required]
    )
    > label {
    @apply after:content-['*'] after:text-danger;
  }
}

.ng-select {
  @apply !static;
  .ng-select-container {
    @apply rounded-xl bg-white text-gray-600 h-full dark:bg-gray-800 dark:text-gray-400 dark:border-gray-500 min-h-11 focus:border-primary focus:outline-none focus:ring-0;
  }
  .ng-dropdown-panel {
    z-index: 999 mp !important;
    position: absolute !important;
  }
  .ng-input {
    @apply py-1;
  }
  .ng-dropdown-panel {
    @apply bg-white dark:bg-gray-700 dark:border-gray-500;
  }
  .ng-dropdown-panel-items {
  }
  .ng-option {
    @apply !text-gray-600 dark:!text-gray-100 bg-white dark:!bg-gray-700;
    &.ng-option-marked,
    &.ng-option-selected.ng-option-marked {
      @apply bg-primary-50 text-primary dark:text-primary-100 dark:!bg-gray-900;
    }
    &.ng-option-selected {
      @apply !bg-primary-100 !text-primary dark:text-primary dark:!bg-gray-900;
    }
  }
}
input#file-upload-button {
  @apply !bg-primary !text-white dark:!text-gray-100 dark:!bg-gray-900;
}
